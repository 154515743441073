import React from 'react';
import { Avatar } from '@mantine/core';

interface SkillBadgeProps {
  skills: string[];
}

const SkillBadge: React.FC<SkillBadgeProps> = ({ skills }) => {
  return (
    <div data-aos="fade-up"
      data-aos-duration="800"
      className="flex flex-wrap justify-center">
      {skills.map((skill: string, index: number) => (
        <div
          key={index}
          className="flex gap-2 border border-textColor p-2 m-2 rounded-2xl items-center"
        >
          <Avatar
            variant="transparent"
            radius="xs"
            src={`/icons/${skill.toLowerCase().replace(/\s+/g, '')}.png`} // Converting skill names to lowercase and removing spaces
            alt={skill}
            size={24}
          />
          <div className='text-textColor text-xl font-medium'>
            {skill}
          </div>
        </div>
      ))}
    </div>
  );
};

const SkillCard = (props: any) => {
  return (
    <div className='w-[49%] border rounded-xl border-primaryColor p-5 mb-5 flex flex-col shadow-[0_0_5px_0_#64ffda] '>
      <div className="text-2xl text-white text-center font-bold">
        {props.skillTitle}
      </div>
      <div className='flex flex-wrap gap-1 justify-center mb-4 '>
        <SkillBadge
          skills={props.skills}
        />
      </div>
    </div>
  );
}

export default SkillCard;
