// import IconCloud from "./magicui/icon-cloud";
import { IconHexagon } from "@tabler/icons-react";

// const slugs = [
//   "typescript",
//   "javascript",
//   "dart",
//   "java",
//   "react",
//   "flutter",
//   "android",
//   "html5",
//   "css3",
//   "nodedotjs",
//   "express",
//   "nextdotjs",
//   "prisma",
//   "amazonaws",
//   "postgresql",
//   "firebase",
//   "nginx",
//   "vercel",
//   "testinglibrary",
//   "jest",
//   "cypress",
//   "docker",
//   "git",
//   "jira",
//   "github",
//   "gitlab",
//   "visualstudiocode",
//   "androidstudio",
//   "sonarqube",
//   "figma",
// ];

export function Loader() {
  return (
    <div className="relative flex h-[100vh] w-[100vw] animate-[ping_1.5s_ease-in-out_1_4.5s] items-center justify-center">
      {/* <IconCloud iconSlugs={slugs} />*/}
      <IconHexagon className="  animate-[spin_5s_linear_infinite]" size={120} color="#64FFDA" stroke={1.25} /> 
      <div className=" absolute font-mono text-primaryColor font-semibold text-6xl -z-10">DS</div>
    </div>
  );
}
