import React from 'react'

const Mail = () => {
  return (
    <div  className='flex text-textColor items-center gap-10 fixed bottom-40 -right-44 rotate-90'>
        <a href="mailto:deepzsenu@gmail.com" className='text-lg tracking-widest font-mono hover:text-primaryColor hover:-translate-x-2 transition transform duration-300'>deepzsenu@gmail.com</a>
        <hr  className='border-[2px] w-40 rounded-full text-textColor bg-textColor border-textColor'/>
    </div>
  )
}

export default Mail