// Header.tsx
import React from 'react';
import { IconHexagonLetterD } from "@tabler/icons-react";
import SideBar from './SideBar';
import MenuOptions from './MenuOptions';

const Header = () => {
  return (
    <nav className="flex justify-between bg-bgColor dm-mono-regular h-[15vh] items-center px-10">
      <h1 className="text-primaryColor ">
        <IconHexagonLetterD size={59} stroke={1.3} />
      </h1>
      <MenuOptions />
      <SideBar />
    </nav>
  );
};

export default Header;
