import React, { useState } from 'react';
import { IconHexagonLetterD, IconX } from "@tabler/icons-react";
import { Drawer, Button, Burger } from '@mantine/core';
import MenuOptions from './MenuOptions';
import './css/header.css'; // Import your custom CSS file

const SideBar = () => {
  const [opened, setOpened] = useState(false);

  // Toggle function to open/close the sidebar
  const toggleSidebar = () => setOpened((prev) => !prev);

  return (
    <>
      <Drawer
        position="right"
        className="md:hidden custom-drawer !-z-0"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        opened={opened}
        onClose={toggleSidebar}  // Close drawer using the toggle function
        closeButtonProps={{ style: { display: 'none' } }}
        size="xs"
      >
        <div className="flex flex-col h-full justify-between">
          {/* Top section with logo and custom close button */}
          <div className="flex items-center justify-between p-4 border-b border-gray-700">
            <h1 className="text-primaryColor ">
              <IconHexagonLetterD size={59} stroke={1.3} />
            </h1>
            <Button variant="subtle" onClick={() => setOpened(false)}>
              <IconX size={59} stroke={1.5} color='#64ffda' />
            </Button>
          </div>

          {/* Menu options in the middle */}
          <div className="flex flex-col items-start space-y-6 p-6">
            <MenuOptions isSidebar={true} />
          </div>

          {/* Bottom section (optional) */}
          <div className="p-4">
            {/* Additional content */}
          </div>
        </div>
      </Drawer>

      {/* Burger button to toggle the sidebar */}
      <Burger
        className="md:!hidden  !z-10 text-primaryColor"
        color="#64ffda"
        size="lg"
        opened={opened}
        onClick={toggleSidebar}  // Toggle sidebar using the function
      />
    </>
  );
};

export default SideBar;
