import React from 'react';
import { skillInfo } from '../../Constants/userData';
import SkillCard from './SkillCard';

const Skills = (props: any) => {
    return (
        <div className='px-16 mx-10 mt-10 mb-6 font-mono' id="skills">
            <h1 className='text-4xl text-white font-bold text-center mb-5'>
                Skills
            </h1>
            <div className='flex flex-wrap gap-5 justify-between'>
            {
                skillInfo.map((skill: any, index: number) =>
                    <SkillCard
                        key={index}
                        skillTitle={skill.title}
                        skills={skill.skills}
                    />
                )
            }
            </div>
        </div>
    );
}

export default Skills;
