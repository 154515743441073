import React, { useState, useEffect, useRef } from 'react';
import { Info, Docs } from '../../Constants/userData';
//@ts-ignore
import NET from 'vanta/dist/vanta.net.min';
import Typewriter from 'typewriter-effect';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Resume from '../ResumeSection/Resume';
import { NeonGradientCard } from '../magicui/neon-gradient-card';

const About = () => {
    const [opened, { close, open }] = useDisclosure(false);
    const [netEffect, setNetEffect] = useState<any>(null);
    const netRef = useRef<HTMLDivElement>(null);

    // NET effect
    useEffect(() => {
        if (!netEffect && netRef.current) {
            setNetEffect(NET({
                el: netRef.current,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                color: 0x122069,
                backgroundColor: 0x112240,
            }));
        }

        return () => {
            if (netEffect) netEffect.destroy();
        };
    }, [netEffect]);

    return (
        <div className='flex px-16 h-[80vh] items-center justify-between overflow-hidden' id='net-bg' ref={netRef}>

            {/* NET background section */}
            <div className='flex-grow flex flex-col w-[67%]'>
                <div className='ml-10 font-mono'>
                    <div className='text-primaryColor text-3xl'>Hi, I am</div>
                    <div className='text-[3.25rem] text-white font-bold'>{Info.name}</div>
                    <div className='text-3xl text-white flex'>
                        I'm a&nbsp;
                        <span className='font-semibold text-primaryColor'>
                            <Typewriter
                                options={{
                                    strings: Info.stack,
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </span>
                    </div>

                    <div className="text-textColor text-xl w-[90%] text-justify my-8 lg-mx:my-0 font-semibold lg-mx:text-base sm-mx:text-sm xs-mx:text-xs">{Info.description}</div>
                    <Button
                        size='lg'
                        variant='filled'
                        onClick={open}

                        styles={{
                            root: {
                                backgroundColor: '#64ffda',
                                marginRight: "10px",
                                color: '#0a192f',
                                '&:hover': {
                                    backgroundColor: '#52e6b6'
                                }
                            }
                        }}
                    >
                        Check Resume
                    </Button>

                    <Button
                        component='a'
                        href={Docs.resume}
                        target='_blank'
                        size='lg'
                        variant='outline'
                        styles={{
                            root: {
                                marginTop: '0px',
                                merginLeft: "0px",
                                color: '#64ffda',
                                borderColor: '#64ffda',
                                '&:hover': {
                                    backgroundColor: '#64ffda',
                                    color: '#0a192f'
                                }
                            }
                        }}
                        download={true}

                    >
                        Download Resume
                    </Button>

                </div>
            </div>

            {/* Image section */}
            <div className="h-fit flex bg-bgColor justify-center items-center rounded-full bs:mr-10 w-fit">
                <NeonGradientCard className="w-[325px] h-[325px] lg-mx:w-64 lg-mx:h-64 xsm-mx:w-56 xsm-mx:h-56 items-center justify-center text-center">
                    <img
                        className="w-full h-full rounded-full bg-bgColor"
                        src={Docs.profileRemoveBg}
                        alt="profile"
                    />
                </NeonGradientCard>
            </div>

            <Resume
                opened={opened}
                close={close}
            />
        </div>
    );
};

export default About;
