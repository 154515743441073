import React from 'react';
import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import StatusIndicator from './Indicator';
import { useDisclosure } from '@mantine/hooks';
import ProjectModal from './ProjectModal';

const ProjectCard = (props: any) => {
    const [opened, { close, open }] = useDisclosure(false);

    return (
        <Card
            data-aos="fade-up"
            data-aos-duration="800"
            className='!bg-bgColor !border-primaryColor !border-2 flex flex-col justify-between h-full hover:shadow-[0_0_10px_1px_#64ffda] hover:scale-105 cursor-pointer transition-transform duration-300 ease-in-out'
            shadow="lg"
            p="sm"
            radius="lg"
            withBorder
            style={{ minHeight: '420px' }}
        >
            <div className='flex-grow'>
                {/* Image Section */}
                <Card.Section className='p-3'>
                    <Image
                        className='!rounded-xl shadow-[0_0_5px_0_#64ffda]'
                        src={props.image}
                        height={160}
                        alt={props.title}
                    />
                </Card.Section>

                {/* Title and Badge Section */}
                <Group justify="space-between" mt="xs" mb="xs">
                    <Text className='text-2xl !text-white flex item-center' fw={500}>
                        {props.title}
                    </Text>
                    <Badge
                        color={props.live ? "green" : "red"}
                        variant="outline"
                        rightSection={<StatusIndicator live={props.live} />}
                    >
                        {props.live ? "Live" : "Not Live"}
                    </Badge>
                </Group>

                {/* Tech Used Badges Section */}
                <Group className='mt-2 mb-2 flex-wrap '>
                    {props.techUsed.map((tech: string, index: number) => (
                        <Badge
                            className='!text-bgColor'
                            key={index}
                            color='#64ffda'
                        >
                            {tech}
                        </Badge>
                    ))}
                </Group>

                {/* Description Section */}
                <Text
                    size="sm"
                    color="dimmed"
                    lineClamp={3}
                    className='text-justify mt-2 flex-grow'
                >
                    {props.desc}
                </Text>
            </div>

            {/* Button Section */}
            <Group justify='center' className="mb-4 mt-6">
                <Button
                    variant="light"
                    className='!bg-primaryColor !text-bgColor'
                    // fullWidth
                    mt="md"
                    radius="md"
                    onClick={open}
                >
                    View More
                </Button>
            </Group>
            <ProjectModal
                opened={opened}
                close={close}  // Pass the close function to the modal
                repoLink={props.githubCode}
                liveLink={props.liveLink}
                projDesc={props.desc}
                projTitle={props.title}
                isLive={props.live}
                image={props.image}
                techUsed= {props.techUsed}
            />
        </Card>
    );
};

export default ProjectCard;
