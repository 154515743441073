import { Modal, ActionIcon } from "@mantine/core";
import { Document, Page } from "react-pdf";
import { Docs } from "../../Constants/userData";
import { IconArrowBigDownLineFilled } from "@tabler/icons-react";

// Set up the worker for react-pdf
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Resume = (props: any) => {

  console.log(Docs.resume);

  return (
    <Modal.Root
      opened={props.opened}
      onClose={props.close}
      size="80%" // Adjust modal size if needed
      className="hide-scrollbar"
      styles={{
        content: {
          width: "80%", // Set a width percentage to fit the screen
          height: "80vh", // Set fixed height to avoid auto resizing issues
          overflowY: "auto", // Scroll within the modal if content overflows
          scrollbarWidth: "none", // For Firefox
          msOverflowStyle: "none", // For IE
        },
      }}
    >
      <Modal.Overlay />
      <Modal.Content className="!rounded-3xl !border-primaryColor !border-2 !font-mono">
        <Modal.Header className="!bg-bgColor !text-primaryColor !font-bold !items-center">
          <Modal.Title>
            <span className="text-2xl mr-5 ">Resume</span>
            <ActionIcon target="_blank" component="a" href={Docs.resume} variant="outline"  color ="#64fxda" aria-label="Setting" download={true}>
              <IconArrowBigDownLineFilled/>
            </ActionIcon>
          </Modal.Title>
          <Modal.CloseButton
            size="md"
            iconSize="30px"
            className="!bg-bgColor !text-red-600"
            color="red"
          />
        </Modal.Header>

        <Modal.Body className="bg-bgColor text-textColor">
          <div className="flex justify-center">
            <Document
              file={Docs.resume} // The PDF file from the Docs object
              onLoadError={(error) => console.error("Error loading PDF:", error)} // Handle PDF load errors
            >
              <Page pageNumber={1} renderTextLayer ={false} renderAnnotationLayer={false}/> {/* Display only the first page */}
            </Document>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default Resume;
