import React from 'react';

// Links for the navigation
const links = ["About", "Skills", "Projects", "Experience", "Contact"];

// Function to render navigation links with dividers for large screens
const navLinks = (isSidebar: boolean) => {
  return links.map((link, index) => (
    <React.Fragment key={link}>
      <a
        className={`hover:text-primaryColor capitalize transition-colors duration-300 ${isSidebar ? 'block mb-2 text-md font-semibold' : 'inline-block text-lg font-semibold'} ${index < links.length - 1 ? 'mr-2' : ''}`}
        href={`#${link.toLowerCase()}`}
      >
        {link}
      </a>
      {/* Add a divider for large screens */}
      {!isSidebar && index < links.length - 1 && (
        <span className="hidden md:inline text-md font-semibold mx-2 text-primaryColor">|</span>
      )}
    </React.Fragment>
  ));
};

const MenuOptions = ({ isSidebar = false }: { isSidebar?: boolean }) => {
  return (
    <div
      className={`flex ${isSidebar ? 'flex-col mt-4 mb-4' : 'items-center gap-2 md:gap-1 mt-2 mb-2'} text-textColor ${isSidebar ? 'text-lg' : 'hidden md:flex'}`}
    >
      {navLinks(isSidebar)}
    </div>
  );
};

export default MenuOptions;
