import React, { useEffect } from 'react';
import "@mantine/core/styles.css";
import { MantineProvider } from '@mantine/core';
import Home from './Components/Home';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from "@vercel/analytics/react"
import { pdfjs } from 'react-pdf';
import AOS from 'aos';
import "aos/dist/aos.css"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function App() {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <MantineProvider>
      <div className="App">
        <Home />
      </div>
      <Analytics />
      <SpeedInsights />
    </MantineProvider >
  );
}

export default App;
