// Importing assets
import Resume from "../assets/DeepakKumarSaxena.pdf";
import Profile from "../assets/profile.jpeg";
import RemovedBg from "../assets/remove-bg.png";
import {
    IconBrandGithub,
    IconBrandLeetcode,
    IconBrandInstagram,
    IconBrandLinkedin
  } from '@tabler/icons-react';

import BrandingBulls from "../assets/companyicon/brandingbulls.png";
import Rangilalls from "../assets/companyicon/rangilalls.svg";
import DowellResearch from "../assets/companyicon/Dowell.png";

export const socialLinks = [
    { link: "https://github.com/deepzsenu", icon: IconBrandGithub },
    { link: "https://www.linkedin.com/in/deepzsenu/", icon: IconBrandLinkedin },
    { link: "https://leetcode.com/u/deepzsenu/", icon: IconBrandLeetcode },
    { link: "https://www.instagram.com/deepsenu7/", icon: IconBrandInstagram }
  ];

// Info object with description and stack
export const Info = {
    name: "Deepak Kumar Saxena",
    description: `Skilled Software Engineer specializing in frontend development, with experience in Machine Learning and 
    Data Science. Passionate about creating seamless user experiences and contributing to innovative projects.`,
    stack: ["Software Engineer", "Full-Stack Developer", "Freelancer", "Competitive Programmer"],
};



// Docs object correctly assigning the imported files
export const Docs = {
    profile: Profile,            // Assign the profile image
    profileRemoveBg: RemovedBg,   // Assign the background-removed profile image
    resume: Resume,              // Assign the resume PDF
};

export const skillInfo = [
    {
        title: "Frontend",
        skills: ['HTML', 'CSS', 'JavaScript', 'React.js', 'React Native', 'Redux', 'Bootstrap', 'Tailwind CSS']
    },
    {
        title: "Backend",
        skills: ['Node', 'Express', 'Django', 'Flask', 'SQL', 'MongoDB', 'PostgreSQL']
    },
    {
        title: "Languages",
        skills: ['Java', 'Python', 'JavaScript', 'TypeScript', 'C++', 'C']
    },
    {
        title: "Tools",
        skills: ['Git', 'GitHub', 'VS Code', 'MongoDB Atlas', 'Postman', 'Linux']
    }
];

export const experienceInfo = [
    {
        title: "Software Engineer",
        companyLogo: Rangilalls,
        company: "Rangi Lalls",
        description: [
            "Developed Vihara.ai's frontend using React.js and Tailwind CSS, creating a fast, responsive user interface.",
            "Collaborated with UX/UI designers to implement pixel-perfect designs and enhance user experience.",
            "Led a cross-functional team of developers, providing mentorship, code reviews, and optimizing workflows using Git and GitHub.",
            "Integrated RESTful APIs with Express.js and MongoDB, improving data synchronization and performance by 30%.",
            "Worked closely with backend team, developing scalable architecture using Django for efficient server-side operations.",
            "Built robust web applications using Django’s ORM and form handling to ensure smooth backend processing.",
            "Developed real-time dynamic dashboards using React.js hooks, improving user engagement by 20%.",
            "Implemented Flask microservices for lightweight API development, optimizing certain backend features for performance.",
            "Optimized Rangi Lalls' website for SEO and performance, reducing load times by 50%.",
            "Implemented CI/CD pipelines using GitHub Actions to streamline development and deployment."
        ],
        skillsUsed: [
            "React.js", "Redux", "Tailwind CSS", "Node.js", "Express", "MongoDB", "Django", "Flask", 
            "Frontend Development", "UI Design", "Team Leadership", "Git", "GitHub", "RESTful APIs", "SEO Optimization"
        ],
        from: "August 2023",
        to: "August 2024"
    },
    {
        title: "Software Engineer",
        companyLogo: BrandingBulls,
        company: "Branding Bulls",
        description: [
            "Developed responsive web apps using HTML, CSS, and JavaScript, ensuring mobile and cross-browser compatibility.",
            "Created reusable React.js components, improving development efficiency and consistency across projects.",
            "Managed state with Redux in large-scale apps, ensuring predictable and maintainable code architecture.",
            "Integrated RESTful APIs to ensure smooth communication between frontend and backend services.",
            "Enhanced UX with dynamic elements using JavaScript and React hooks, increasing engagement by 15%.",
            "Built landing pages using Bootstrap and Tailwind CSS, improving performance and load times.",
            "Ensured accessibility compliance and optimized web pages for screen readers.",
            "Reduced page load times by 40% through performance optimizations and lazy loading."
        ],
        skillsUsed: [
            "HTML", "CSS", "JavaScript", "React.js", "Redux", "Bootstrap", "Tailwind CSS", 
            "Responsive Design", "UI Development", "RESTful APIs", "Performance Optimization"
        ],
        from: "January 2022",
        to: "July 2023"
    },
    {
        title: "Data Science Intern",
        companyLogo: DowellResearch,
        company: "Dowell Research",
        description: [
            "Engineered a loginID function using face and speech recognition with Python, OpenCV, and Pandas.",
            "Converted images to CSV format by extracting pixel data for machine learning models.",
            "Extracted and analyzed video frames with Python-based image processing techniques, reducing manual work by 60%.",
            "Built backend APIs with Flask for real-time data retrieval, integrating machine learning models seamlessly.",
            "Deployed Flask web applications to serve image recognition services, improving system scalability.",
            "Collaborated on custom facial detection and speech recognition algorithms for seamless integration.",
            "Processed large datasets using Pandas and NumPy, improving model training accuracy and efficiency.",
            "Developed data pipelines for object detection models using TensorFlow, saving output as CSV for analysis.",
            "Optimized data retrieval processes with PostgreSQL, speeding up query processing by 35%."
        ],
        skillsUsed: [
            "Python", "Pandas", "NumPy", "TensorFlow", "OpenCV", "Data Science", "Face Recognition", 
            "Image Processing", "PostgreSQL", "Flask", "Machine Learning"
        ],
        from: "February 2021",
        to: "November 2021"
    }
];


export const Slugs = [
    "typescript",
    "spring",
    "javascript",
    "dart",
    "java",
    "react",
    "angular",
    "flutter",
    "android",
    "html5",
    "css3",
    "springboot",
    "mongodb",
    "selenium",
    "nodedotjs",
    "express",
    "nextdotjs",
    "prisma",
    "mysql",
    "amazonaws",
    "postgresql",
    "firebase",
    "nginx",
    "vercel",
    "testinglibrary",
    "jest",
    "cypress",
    "docker",
    "git",
    "jira",
    "github",
    "gitlab",
    "visualstudiocode",
    "androidstudio",
    "sonarqube",
    "figma",
];