import { useState } from 'react';

interface AlertState {
  show: boolean;
  text: string;
  type: 'danger' | 'success' | 'warning';  // You can extend this with more types if needed
}

const useAlert = () => {
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    text: '',
    type: 'danger',
  });

  // Updated function signature with proper destructuring
  const showAlert = ({ text, type = 'danger' }: { text: string; type?: 'danger' | 'success' | 'warning' }) => {
    setAlert({
      show: true,
      text,
      type,
    });
  };

  const hideAlert = () => {
    setAlert({
      show: false,
      text: '',
      type: 'danger',
    });
  };

  return { alert, showAlert, hideAlert };
};

export default useAlert;
