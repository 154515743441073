import React from 'react'
import { Timeline } from '@mantine/core';
import { experienceInfo } from '../../Constants/userData';
import TimeLineItems from './TimeLineItems';

const Experience = (props: any) => {
    return (
        <div
        
         className='px-16 mx-10 mt-10 mb-6 font-mono' id="experience">
            <h1 className='text-4xl text-white font-bold text-center mb-5'>
                Experience
            </h1>

            <Timeline color='#64ffda"' active={5} bulletSize={48} lineWidth={2}>

                <TimeLineItems
                    items={experienceInfo}
                />

            </Timeline>
        </div>
    )
}

export default Experience