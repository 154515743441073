import React from 'react';
import ProjectCard from './ProjectCard';
import { ProjectInfo } from '../../Constants/ProjectData';



const Projects = () => {

  

  return (
    <div className='px-16 mx-10 font-mono mt-10 ' id="projects">
      <h1 className='text-4xl text-white font-bold text-center mb-5'>
        &nbsp;Projects
      </h1>
      <div 
        className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 ' 
        // Replace flexbox with grid for better column control
      >
        {ProjectInfo.map((project: any, index: number) => (
          <ProjectCard
            key={index}
            title={project.title}
            desc={project.desc}
            image={project.image}
            live={project.live}
            liveLink={project.liveLink}
            githubCode={project.githubCode}
            techUsed={project.techUsed}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
