import React from 'react';
import { Indicator,Group } from '@mantine/core';

// Rename your component to avoid conflict
const StatusIndicator = (props:any) => {
  return (
    <Group justify='center'>
      <Indicator color={props.live ? "green" : "red"} position="middle-end" radius="sm" size={8} processing={props.live ? true : false}>

      </Indicator>
    </Group>
  );
};

export default StatusIndicator;
