import Portfolio3d from "../assets/Projects/portfolio3d.png"
import Portfolio from "../assets/Projects/portfolio.png"
import Vihara from "../assets/Projects/vihara.png"
import TODO from "../assets/Projects/todo.png"

export const ProjectInfo = [
  {
    title: "Vihara.ai",
    desc: `Vihara.ai is an innovative platform dedicated to leveraging artificial intelligence for better decision-making. It showcases advanced AI solutions tailored for businesses, emphasizing efficiency and data-driven strategies. The website features a modern, user-friendly interface, making it easy for users to navigate and explore the various AI services offered.`,
    image: Vihara, // Replace with an appropriate image link
    live: true,
    techUsed: ["Node.js", "React", "HTML", "CSS", "Tailwind CSS"],
    liveLink: "https://www.vihara.ai/",
    githubCode: "",
  },
   
    {
      title: "3D Portfolio Website",
      desc: `A dynamic 3D portfolio designed to highlight my projects, skills, and experience through immersive web technologies. Built using Three.js for 3D effects, it's a visually interactive way to showcase my work in a modern and engaging format.`,
      image: Portfolio3d,
      live: true,
      techUsed: ["HTML", "CSS", "JavaScript", "React","Redux","Threejs","Tailwind CSS"],
      liveLink: "https://protfolio-3d.vercel.app/",
      githubCode: "https://github.com/deepzsenu/protfolio-3d/",
    },
    {
      title: "Portfolio",
      desc: `A sleek, personal portfolio website featuring my professional journey, key projects, and technical skills. It’s designed with an elegant user interface, ensuring a clean and professional online presence.`,
      image: Portfolio,
      live: true,
      techUsed: ["React", "Node.js", "Express", "emailjs","Tailwind CSS", "Mantine", "Tabler"],
      liveLink: "https://www.deepaksaxena.in/",
      githubCode: "https://github.com/example/ecommerce-store",
    },
    // {
    //   title: "Travelo APP",
    //   desc: `A fitness tracking application that allows users to log their workouts and track progress over time.`,
    //   image: "https://via.placeholder.com/300x200",
    //   live:false,
    //   techUsed: ["React", "Redux", "Node.js", "MongoDB"],
    //   liveLink: "https://example.com/fitness-tracker",
    //   githubCode: "https://github.com/example/fitness-tracker",
    // },
    {
      title: "ToDo App",
      desc: `A simple yet functional task management application where users can add, delete, and categorize tasks. The app also supports task creation with due dates, which are displayed in a user-friendly format. Built using Express.js and MongoDB, the server-side logic ensures smooth task handling and database operations. The front-end uses EJS for rendering dynamic views with data fetched from the database.`,
      image:TODO,
      live: false,
      techUsed: ["express js", "Node.js", "ejs", "CSS", "html", "MongoDB"],
      liveLink: "https://example.com/blog-platform",
      githubCode: "https://github.com/deepzsenu/TodoApp/tree/master",
    },
    // {
    //   title: "Weather App",
    //   desc: `A simple weather application that provides real-time weather data for any location. The app uses a public weather API to fetch the data.`,
    //   image: "https://via.placeholder.com/300x200",
    //   live: true,
    //   techUsed: ["React", "API", "CSS", "JavaScript"],
    //   liveLink: "https://example.com/weather-app",
    //   githubCode: "https://github.com/example/weather-app",
    // },
    
  ];
  