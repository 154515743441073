import React from 'react';
import { Timeline, Text, Avatar, Badge, Group, List } from '@mantine/core';
import { IconBriefcaseFilled } from "@tabler/icons-react";

interface ExperienceItemProps {
    title: string;
    companyLogo: string;
    company: string;
    description: string[];
    skillsUsed: string[];
    from: string;
    to: string;
}

const TimeLineItems: React.FC<{ items: ExperienceItemProps[] }> = ({ items }) => {
    return (
        <div
            data-aos="fade-up"
            data-aos-duration="800"
        >
            {items.map((item, index) => (
                <Timeline.Item
                    key={index}
                    bullet={<IconBriefcaseFilled size={30} className='!text-bgColor' />}
                >
                    <div
                        style={{
                            backgroundColor: '#0a192f',
                            padding: '16px',
                            borderRadius: '12px',
                            border: '1px solid #64ffda',
                            color: '#8892b0',
                        }}
                    >
                        {/* Title, Logo, and Company */}
                        <Group align="center" mb={10}>
                            <Avatar src={item.companyLogo} alt={item.company} radius="sm" size="lg" />
                            <div>
                                <Text fw={700} size="xl" style={{ color: '#64ffda' }}>
                                    {item.title}
                                </Text>
                                <Text size="lg" style={{ color: '#8892b0' }}>
                                    {item.company}, <span className="text-sm">{item.from} - {item.to}</span>
                                </Text>
                            </div>
                        </Group>

                        {/* Description */}
                        <List listStyleType="disc" withPadding className='text-textColor text-sm !pr-7'>
                            {item.description.map((desc, i) => (
                                <List.Item key={i} className="text-xs" >{desc}</List.Item>
                            ))}
                        </List>

                        {/* Skills */}
                        <Group mt={12}>
                            <Text fw={700} size="lg" style={{ color: '#64ffda' }}>
                                Skills:
                            </Text>
                            {item.skillsUsed.map((skill, i) => (
                                <Badge key={i} color="#c281cd" size="sm" radius="lg" variant="outline">
                                    {skill}
                                </Badge>
                            ))}
                        </Group>
                    </div>
                </Timeline.Item>
            ))}
        </div>
    );
};

export default TimeLineItems;
