import { useState, useRef } from "react";
import FloatingInput from "./FloatingInput";
import { Button, useMatches } from "@mantine/core";
import { IconArrowRight, IconTopologyStar3 } from "@tabler/icons-react";
import emailjs from "@emailjs/browser";
import useAlert from "../../Hooks/useAlert"; // Import useAlert
import Alert from "../Alert"; // Import Alert component
// import { validateForm } from "./Validation"; // Import validateForm function
import validateForm from "./Validation";

// Define types for the form data and errors
type FormData = {
    name: string;
    email: string;
    phone: string;
    message: string;
};

const Contact = () => {
    const formRef = useRef<HTMLFormElement>(null);

    // Define the initial form state
    const form: FormData = {
        name: "",
        email: "",
        phone: "",
        message: "",
    };

    const [formData, setFormData] = useState<FormData>(form);
    const [formError, setFormError] = useState<Partial<FormData>>({});

    // Integrating custom alert
    const { alert, showAlert, hideAlert } = useAlert();

    const handleChange = (id: keyof FormData, value: string) => {
        setFormData({ ...formData, [id]: value });
        setFormError({ ...formError, [id]: validateForm(id, value) });
    };

    const handleSubmit = async () => {
        let valid = true;
        let newFormError: Partial<FormData> = {};

        // Validate form fields
        for (let key in formData) {
            const error = validateForm(key as keyof FormData, formData[key as keyof FormData]);
            if (error.length > 0) {
                newFormError[key as keyof FormData] = error;
                valid = false;
            }
        }
        setFormError(newFormError);
        
        if (valid) {
            // Send email using EmailJS
            try {
                await emailjs.send(

                    process.env.REACT_APP_SERVICE_ID!, // Your EmailJS service ID
                    process.env.REACT_APP_TEMPLATE_ID!, // Your EmailJS template ID
                    {
                        from_name: formData.name, // Sender's name
                        to_name: "Deepak Kumar Saxena", // Your name or recipient's name
                        from_email: formData.email, // Sender's email
                        to_email: "deepzsenu@gmail.com", // Your email
                        phone_number: formData.phone, // Sender's phone number
                        message: formData.message, // Sender's message
                    },
                    process.env.REACT_APP_PUBLIC_KEY! // Your EmailJS public key
                );

                setFormData(form); // Reset the form
                showAlert({ text: "Message sent successfully!", type: "success" });

                setTimeout(() => {
                    hideAlert();
                }, 3000); // Hide the alert after 3 seconds
            } catch (error) {
                console.error("Failed to send message:", error);
                showAlert({ text: "Message failed to send!", type: "danger" });

                setTimeout(() => {
                    hideAlert();
                }, 3000); // Hide the alert after 3 seconds
            }
        } else {
            showAlert({ text: "Please correct the errors!", type: "danger" });

            setTimeout(() => {
                hideAlert();
            }, 3000); // Hide the alert after 3 seconds
        }
    };

    const btn = useMatches({
        xsm: "xs",
        sm: "sm",
        md: "md",
        lg: "lg",
    });

    return (
        <div className="px-16 md-mx:px-8 sm-mx:px-4 mx-20 lg-mx:mx-10 md-mx:mx-0 my-10 font-mono" id="contact">
            <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl mb-10 font-bold text-center text-white">
                <span className="text-primaryColor">05.&nbsp;</span>Contact
            </h1>

            {/* Show alert if it exists */}
            {alert.show && <Alert {...alert} />}

            <div
                data-aos="flip-left"
                data-aos-duration="800"
                className="w-[70%] lg-mx:w-full shadow-[0_0_10px_0_#64FFDA50] m-auto flex flex-col gap-6 border border-primaryColor p-8 rounded-3xl sm-mx:p-4"
            >
                <div className="text-3xl flex gap-2 items-center text-white font-semibold sm-mx:text-2xl xs-mx:text-xl">
                    Let's Connect
                    <IconTopologyStar3 className="w-10 text-primaryColor h-10 sm-mx:w-7 sm-mx:h-7" />
                </div>

                <FloatingInput
                    id="name"
                    name="Name"
                    value={formData.name}
                    handleChange={handleChange}
                    error={formError.name}
                />
                <FloatingInput
                    id="email"
                    name="Email"
                    value={formData.email}
                    handleChange={handleChange}
                    error={formError.email}
                />
                <FloatingInput
                    id="phone"
                    name="Phone Number"
                    value={formData.phone}
                    handleChange={handleChange}
                    error={formError.phone}
                />
                <FloatingInput
                    id="message"
                    name="Message"
                    value={formData.message}
                    handleChange={handleChange}
                    error={formError.message}
                />

                <Button
                    fullWidth
                    onClick={handleSubmit}
                    rightSection={<IconArrowRight size={20} />}
                    className="!text-bgColor !font-bold"
                    variant="filled"
                    size={btn}
                    radius="lg"
                    color="#64FFDA"
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

export default Contact;
